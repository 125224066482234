/* desktop */

.introContainer {
    margin-top: 32px;
    margin-left: 175px;
}

.title {
    font-weight: 510;
    font-size: 80px;
    line-height: 80px;
    color: #101B3D;
    width: 600px;
}

.subtitle {
    font-size: 21px;
    line-height: 30px;
    color: #677075;
    width: 540px;
    margin: 25px 0;
}

span {
    color: #A98ED6;
}

button {
    border: none;
    width: 150px;
    height: 40px;
    background: #11181C;
    border-radius: 8px;
    font-weight: 510;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 55px;
    transition: all 0.2s ease-in-out;
}

button:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.noMargin {
    margin-left: 0;
}

.slider {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    margin-left: 160px;
}

.cardContainer {
    height: fit-content;
    width: 400px !important;
    outline-style: none;
    margin-left: 15px;
}

.card {
    width: 400px !important;
    height: 400px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    display: flex !important;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.card:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.tag {
    position: absolute;
    top: 15px;
    left: 15px;
    width: auto;
    padding: 6px 10px;
    background: #FCFBFE;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    letter-spacing: 0.05em;
    color: #677075;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mockup {
    width: 460px;
    height: auto;
    animation: fadeIn 1.5s ease-in-out;
}

.name {
    font-size: 16px;
    color: #101B3D;
    margin-top: 16px;
    margin-bottom: 6px;
    font-weight: 510;
}

.description {
    font-size: 16px;
    line-height: 24px;
    color: #677075;
}

/* tablet */
@media screen and (max-width: 1024px) {
    .introContainer {
        margin-top: 22px;
        margin-left: 57px;
        margin-right: 27px;
    }
    .title {
        width: 460px;
        font-size: 60px;
        line-height: 60px;
    }
    .subtitle {
        width: 390px;
        font-size: 18px;
        line-height: 26px;
    }
    .slider {
        margin-left: 42px;
    }
}
@media screen and (max-width: 768px) {
    .card:hover {
        filter: none;
    }
    .button:hover {
        filter: none;
    }
}

/* mobile */
@media screen and (max-width: 520px) {
    .introContainer {
        margin-top: 12px;
        margin-left: 27px;
        margin-right: 27px;
    }
    .title {
        width: 100%;
        font-size: 44px;
        line-height: 50px;
    }
    .subtitle {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        margin-top: 14px;
        margin-bottom: 18px;
    }
    button {
        width: 120px;
        height: 34px;
        font-size: 12px;
    }
    .slider {
        margin-left: 12px;
    }
    .cardContainer {
        width: 300px !important;
    }
    .card {
        width: 300px !important;
        height: 300px;
    }
    .tag {
        padding: 4px 8px;
        font-size: 8px;
    }
    .mockup {
        width: 350px;
    }
    .name {
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .description {
        font-size: 13px;
        line-height: 17px;
    }
}

@media screen and (max-width: 375px) {
    .cardContainer {
        margin-right: 20px;
    }
}
