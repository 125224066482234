/* desktop */

.container {
    font-family: 'Inter', sans-serif;
    padding: 43px 55px;
    display: flex;
    justify-content: space-between;
}

.heart {
    color: #11181C;
    width: 30px;
    height: 28px;
    transition: all 0.2s ease-in-out;
}

.link {
    text-decoration: none;
    color: #677075;
    margin-left: 50px;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
}

.heart:hover {
    color: #A98ED6;
}

.link:hover {
    color: #11181C;
}

a {
    text-decoration: none;
}

/* tablet */
@media screen and (max-width: 1024px) {
    .container {
        padding: 33px 57px;
    }
    .heart {
        height: 23px;
        width: 25px;
    }
    .link {
        font-size: 14px;
    }
}

/* mobile */
@media screen and (max-width: 520px) {
    .container {
        padding: 24px 27px;
    }
    .heart {
        height: 18px;
        width: 20px;
    }
    .mobileLink {
        font-size: 12px;
        color: #677075;
        text-decoration: none;
    }
}
