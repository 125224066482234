/* desktop */

.container {
    margin: 32px 175px;
}

.title {
    font-weight: 510;
    font-size: 80px;
    line-height: 80px;
    color: #101B3D;
    width: 640px;
}

.description {
    font-size: 21px;
    line-height: 30px;
    color: #677075;
    width: 60vw;
    margin: 25px 0;
}

button {
    border: none;
    width: 150px;
    height: 40px;
    background: #11181C;
    border-radius: 8px;
    font-weight: 510;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 55px;
    transition: all 0.2s ease-in-out;
    margin-right: 40px;
}

button:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.gifContainer {
    display: flex;
    justify-content: space-between;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.dimensionGif {
    width: 37vw;
    height: auto;
    pointer-events: none;
    border-radius: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 30px;
    animation: fadeIn 1.5s ease-in-out;
}

.gif {
    width: 22vw;
    height: auto;
    pointer-events: none;
    border-radius: 3.5vw;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 30px;
    animation: fadeIn 1.5s ease-in-out;
}

.subtitle {
    font-weight: 510;
    font-size: 30px;
    line-height: 30px;
    color: #101B3D;
    margin-top: 45px;
    margin-bottom: 15px;
}

.pillContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
}

.pillRoleContainer {
    display: flex;
    margin-bottom: 18px;
    align-items: center;
}

.pillName, .pillName a {
    font-size: 18px;
    line-height: 16px;
    color: #677075;
    transition: all 0.2s ease-in-out;
}

.pillName a {
    border-bottom: #878F94 solid 1px;
}

.pillName a:hover {
    color: #11181C;
    border-bottom: #11181C solid 1px;
}

.pill {
    background: #FDFBFD;
    border-radius: 16px;
    padding: 8px 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-weight: 590;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-right: 20px;
}

.highlight {
    font-size: 18px;
    line-height: 26px;
    color: #677075;
    background: #FCFBFE;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    padding: 22px 26px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1440px) {
    .dimensionGif {
        width: 35vw;
    }
}

@media screen and (max-width: 1200px) {
    .gif {
        width: 21vw;
    }
    .dimensionGif {
        width: 32vw;
    }
}

/* tablet */
@media screen and (max-width: 1024px) {
    .container {
        margin: 22px 57px;
    }
    .title {
        width: 500px;
        font-size: 60px;
        line-height: 60px;
    }
    .button {
        margin-bottom: 40px;
    }
    .description {
        font-size: 18px;
        line-height: 26px;
        width: 75vw;
    }
    .gif {
        width: 25vw;
        border-radius: 4vw;
    }
    .dimensionGif {
        width: 40vw;
    }
    .subtitle {
        font-size: 24px;
        margin-top: 35px;
        margin-bottom: 10px;
    }
    .pill {
        font-size: 12px;
        margin-right: 18px;
    }
    .highlight {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    .pillName, .pillName a {
        font-size: 15px;
        line-height: 15px;
    }
}

@media screen and (max-width: 650px) {
    .dimensionGif {
        width: 39vw;
    }
}

@media screen and (max-width: 600px) {
    .title {
        width: 100%;
    }
}

/* mobile */
@media screen and (max-width: 520px) {
    .container {
        margin-top: 12px;
        margin-left: 27px;
        margin-right: 27px;
        text-align: center;
    }
    .title {
        font-size: 44px;
        line-height: 50px;
    }
    .description {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        margin-top: 14px;
        margin-bottom: 18px;
    }
    button {
        width: 120px;
        height: 34px;
        font-size: 12px;
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 30px;
    }
    .gifContainer {
        flex-direction: column;
        align-items: center;
    }
    .gif {
        margin-bottom: 30px;
        width: 65vw;
        border-radius: 10.5vw;
    }
    .dimensionGif {
        width: 75vw;
    }
    .subtitle {
        font-size: 20px;
    }
    .pillContainer {
        justify-content: center;
        row-gap: 12px;
    }
    .pill {
        margin-right: 15px;
        font-size: 10px;
        line-height: 10px;
        padding: 8px 12px;
    }
    .highlight {
        font-size: 13px;
        line-height: 18px;
        text-align: left;
        padding: 18px;
        margin-bottom: 25px;
    }
    .pillName, .pillName a {
        font-size: 12px;
        line-height: 12px;
    }
    .pillRoleContainer {
        justify-content: center;
    }
}

@media screen and (max-width: 345px) {
    .pillName, .pillName a {
        font-size: 10px;
        line-height: 10px;
    }
}
