body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -moz-user-select: none;
}

.szh-menu {
  min-width: 30px !important;
}

.slick-next {
  right: 25px !important;
}

.slick-prev, .slick-next {
  width: 16px !important;
  height: 16px !important;
  background: #FDFBFD !important;
  border-radius: 36px !important;
  padding: 16px !important;
  z-index: 99;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  top: 40% !important;
}

.none {
  display: none !important;
}

.card0 {
  background-image: linear-gradient(to right bottom, #d8d6ff, #e7dbfc, #f2e1fa, #f9e8f9, #fef0f9);
}

.card1 {
  background-image: linear-gradient(to right bottom, #fef0f9, #f7ecfb, #eceafe, #dee8ff, #cee7fd);
}

.card2 {
  background-image: linear-gradient(to right bottom, #cee7fd, #ceecfc, #d0f0fa, #d5f4f7, #dcf7f5);
}

.card3 {
  background-image: linear-gradient(to right bottom, #dcf7f5, #dcf8f0, #dff9eb, #e4f9e4, #ebf8de);
}

.card4 {
  background-image: linear-gradient(to right bottom, #ebf8de, #f1f5da, #f7f1d8, #fceed7, #ffebd8);
}

.card5 {
  background-image: linear-gradient(to right bottom, #ffebd8, #ffe5d8, #ffdfdc, #ffdbe1, #f7d8e7);
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out, transform 300ms ease;
}
